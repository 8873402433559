import './App.css';
import Slider from './Slider';
import Nav from './Nav';
import PageContentContainer from './PageContentContainer';

function App() {
  return (
    <div id='app'>
      <header>
        <Slider />
        <Nav />
      </header>
      <PageContentContainer />
    </div>
  );
}

export default App;
