import { Link } from "react-router-dom";
import './Trasparenza.css'

function Trasparenza() {


    return (

        <div className="center">
            <div className="text space">
                Settemotivi - Legge Trasparenza Anno 2024
            </div>
            <div className="text space">
                Settemotivi - ODV non ha ricevuto nell'anno 2024 contributi pubblici superiori a € 10.000/00.
            </div>
            <div>
                <table>
                    <tr>
                        <th>Soggetto erogatore</th>
                        <th>Data ricevimento</th>
                        <th>Importo erogato</th>
                        <th>Riferimento</th>
                    </tr>
                    <tr>
                        <td>COMUNE DI PORDENONE</td>
                        <td>12/04/2024</td>
                        <td>€ 500,00</td>
                        <td>SALDO CONTRIBUTO ORDINARIO PER IL SOCIALE - ANNO 2023</td>
                    </tr>

                    <tr>
                        <td>COMUNE DI PRATA DI PORDENONE</td>
                        <td>20/05/2024</td>
                        <td>€ 50,00</td>
                        <td>
                            SALDO CONTRIBUTO ORDINARIO ANNO 2023 ALLE ASSOCIAZIONI
                        </td>
                    </tr>

                    <tr>
                        <td>REGIONE FRIULI VENEZIA GIULIA</td>
                        <td>08/07/2024</td>
                        <td>€ 2000,00</td>
                        <td>SALDO PROGETTO LOCALI STORICI - LEGGE REGIONALE 9 NOVEMBRE 2012 - ANNO 2023</td>
                    </tr>

                    <tr>
                        <td>REGIONE FRIULI VENEZIA GIULIA</td>
                        <td>16/07/2024</td>
                        <td>€ 200,00</td>
                        <td>CONTRIBUTO RIMBORSO ASSICURAZIONE OBBLIGATORIA VOLONTARI - LEGGE REGIONALE 9 NOVEMBRE 2012
                        </td>
                    </tr>

                    <tr>
                        <td>REGIONE FRIULI VENEZIA GIULIA</td>
                        <td>18/09/2024</td>
                        <td>€ 1824,00</td>
                        <td>CONTRIBUTO ACQUISTO ATTREZZATURA - - LEGGE REGIONALE 9 NOVEMBRE 2012
                        </td>
                    </tr>

                    <tr>
                        <td>REGIONE FRIULI VENEZIA GIULIA</td>
                        <td>04/10/2024</td>
                        <td>€ 1824,00</td>
                        <td>ACCONTO RELATIVO AL PROGETTO "LE BIBLIOTECHE PORDENONESI" ANNO 2024/2025 - LEGGE REGIONALE 9 NOVEMBRE 2012
                        </td>
                    </tr>

                    <tr>
                        <td>COMUNE DI PRATA DI PORDENONE</td>
                        <td>29/10/2024</td>
                        <td>€ 150,00</td>
                        <td>
                            ACCONTO CONTRIBUTO ATTIVITA' ORDINARIA ANNO 2024
                        </td>
                    </tr>

                </table>
            </div>
            <div style={{ "margin": "1rem" }}>
                pubblicazione dei contributi ricevuti ai sensi della legge 4 agosto 2017 n. 124 art. 1, commi da 125 a 127
            </div>
            <div style={{ "margin": "1rem" }}>
                <Link to={"/"}>Torna alla home</Link>

            </div>
        </div>
    )
}

export default Trasparenza;